#name:focus {
    box-shadow: 0 0 5px rgba(87, 107, 52, 1);
    border: 1px solid rgba(87, 107, 52, 1);
}

#email:focus {
    box-shadow: 0 0 5px rgba(87, 107, 52, 1);
    border: 1px solid rgba(87, 107, 52, 1);
}

#subject:focus {
    box-shadow: 0 0 5px rgba(87, 107, 52, 1);
    border: 1px solid rgba(87, 107, 52, 1);
}

#message:focus {
    box-shadow: 0 0 5px rgba(87, 107, 52, 1);
    border: 1px solid rgba(87, 107, 52, 1);
}