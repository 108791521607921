.row {
    margin-left: auto;
    margin-right: auto;
}

.row .col .cssCard {
    width: 18rem;
    height: 27rem; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: none;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff; 
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-top: 25px;
    border-radius: 15px;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }