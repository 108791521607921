.pricing-header {
    /* limit header width so that the paragraph is easier to read*/
    max-width: 700px;
  }
  
  .container {
    /* overwirte all max-width value smaller than 960px in all media queries */
    /* overwriting max-width 540 @ mw 576 & max-width 720 @ mw 768 */
    /* providing wider width (smaller x-margin) across tablet and dektop */
    max-width: 960px;
  }
  
  .card-deck .card {
    /* make sure the cards don't get too narrow */
    min-width: 220px;
  }